import CmsHooks from "@shared/utils/register/CmsHooks";
import { HOOKS } from "@shared/utils/register/definitions";
import getEditorComponent from "@project/utils/getEditorComponent";
import getDynamicAdminContentsTypes from "@project/utils/getDynamicAdminContentsTypes";
import getFormComponent from "@project/utils/getFormComponent";
import customContents from "@project/contents/customContents";
import customContentTypes from "@project/enums/contentsCustomTypes.enum";

export default () => {
  CmsHooks.registerHook(
    HOOKS.templateAdminUtils_getEditorComponent,
    getEditorComponent,
  );
  CmsHooks.registerHook(
    HOOKS.templateAdminUtils_getDynamicAdminContentsTypes,
    getDynamicAdminContentsTypes,
  );
  CmsHooks.registerHook(HOOKS.ContentForm_getFormComponent, getFormComponent);
  CmsHooks.registerHook(HOOKS.adminContentsUtils_contents, customContents);
  CmsHooks.registerHook(HOOKS.Custom_content_types, customContentTypes);
};
